import { Translation } from '@jsverse/transloco';
import { AES, enc } from 'crypto-js';

export function extractLabels(labels: Translation): Translation {
	// eslint-disable-next-line dot-notation
	const content = labels['__content']; // Prioritize encrypted content
	if (!content) {
		// Use as unencrypted labels
		return labels;
	}
	return <Translation>JSON.parse(AES.decrypt(content, 'b0k2-7Z82f3e9A1t1').toString(enc.Utf8));
}

import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, isDevMode } from '@angular/core';
import { TranslocoModule, provideTransloco } from '@jsverse/transloco';

import { LanguageCode } from './language-code';
import { DEFAULT_AVAILABLE_LANGUAGES } from './transloco-defualt.config';
import { TranslocoHttpLoader } from './transloco-http.loader';

@NgModule({
	imports: [TranslocoModule, HttpClientModule],
	exports: [TranslocoModule]
})
export class TranslocoRootModule {
	/**
	 * Setup application configuration for transloco.
	 */
	public static forRoot(flatten?: boolean): ModuleWithProviders<TranslocoRootModule> {
		return {
			ngModule: TranslocoRootModule,
			providers: [
				provideTransloco({
					config: {
						defaultLang: LanguageCode.English,
						fallbackLang: LanguageCode.English,
						availableLangs: DEFAULT_AVAILABLE_LANGUAGES,
						prodMode: !isDevMode(),
						reRenderOnLangChange: true,
						flatten: { aot: flatten !== undefined ? flatten : !isDevMode() },
						missingHandler: {
							logMissingKey: true,
							allowEmpty: false,
							useFallbackTranslation: true
						}
					},
					loader: TranslocoHttpLoader
				})
			]
		};
	}
}
